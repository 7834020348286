@keyframes fade-up {
  from {
    transform: translate(0, 100vh);
  }
  to {
    transform: translate(0, 0);
  }
}

.fade-up {
  animation: fade-up 0.6s ease;
  animation-delay: 0.2s;
  animation-fill-mode: both;
}

.moving-gradient {
  background-size: 150% 150%;
  animation: moving-gradient 10s ease infinite;
}

@keyframes moving-gradient {
  0% {
    background-position: 0%;
  }
  50% {
    background-position: 50%;
  }
  100% {
    background-position: 0%;
  }
}

.dropdown-wrapper {
  cursor: pointer;
}

.dropdown {
  transform: translateY(-50%);
  transition: all 0.2s ease-in-out;
  top: 120%;
}

.dropdown-wrapper:hover .dropdown {
  opacity: 0.9;
  transform: translateY(0);
}

.searchbox:focus-within > .searchbox-icon {
  opacity: 1;
}

.notifications {
  opacity: 0;
  transform: translateY(-100%) translateX(-50%);
  transition: all 0.2s ease-in-out;
  z-index: -10;
}

.notif-wrapper:focus-within .notifications {
  opacity: 0.95;
  transform: translateY(0) translateX(-50%);
}
