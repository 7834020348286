.portfolio-content-artwork-img {
  width: 160px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  align-items: center;
}

.portfolio-editor-choose-experience-artwork{
  width: 120px;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  align-items: center;
}

.portfolio-form {
  grid-template-columns: auto 1fr;
}

.portfolio-form-short {
  grid-template-columns: auto 1fr 1fr 1fr;
}
.portfolio-editor-contact-visible {
  display: flex;
  cursor: pointer;
  justify-content: left;
  align-items: left;
  height: 100%;
  font-size: 16px;
}

.portfolio-editor-section-visible {
  display: flex;
  cursor: pointer;
  justify-content: right;
  align-items: right;
  height: 100%;
}

.portfolio-editor-section-checkbox{
	background-color: #ebebeb;
	border: 1px solid #cacece;
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
	padding: 9px;
  margin-top: 3px;
	border-radius: 3px;
	display: inline-block;
	position: relative;
}

#portfolio-editor-experience-remove-button{
  display: flex;
  justify-content: right;
  align-items: right;
  height: 100%;
}

.portfolio-editor-layout-div {
  width: 208px;
  margin-left: 20px;
  margin-right: 20px;
}
