@tailwind base;
@tailwind components;
@tailwind utilities;

/* poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,400;1,500;1,600;1,700;1,900&display=swap");

/* inter */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@layer utilities {
  .container {
    @apply px-4 sm:px-8 md:px-12 lg:px-16 lg:max-w-6xl xl:max-w-7xl;
  }

  input[type="text"],
  input[type="search"],
  input[type="password"],
  input[type="email"],
  textarea {
    @apply px-2 py-1 min-w-full outline-none text-white bg-transparent border-opacity-50 focus:border-opacity-100 border-gray-200 transition;
    border-bottom-width: 1px;
  }

  .blurry-text {
    /* color: transparent; */
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .underline-offset {
    text-underline-offset: 3px;
  }
}

html {
  scroll-behavior: smooth;
  color-scheme: dark;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.appear {
  animation: appear 0.2s ease;
  animation-delay: 0.2s;
  animation-fill-mode: both;
}
